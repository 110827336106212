<template>
  <div class="salePay">
    <h1>销售收款</h1>
    <el-form :inline="true">
      <el-form-item label="收款日期">
        <el-date-picker v-model="searchParam.payDates" type="daterange" start-placeholder="起" end-placeholder="止" style="width: 220px;" />
      </el-form-item>
      <el-form-item label="客户">
        <el-select v-model="searchParam.custom" filterable style="width: 120px;" clearable>
          <el-option v-for='customItem in show.customList' :key="customItem.id" :label="customItem.value" :value="customItem.value" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button-group>
          <el-button type="primary" @click="commitFindList()">搜索</el-button>
          <el-button @click="resetSearchParam()">重置</el-button>
        </el-button-group>
      </el-form-item>
    </el-form>
    <el-table :data="data.list" @selection-change="selectionChange()" ref="tabRef" border show-summary :summary-method="summary" style="height: calc(100vh - 310px);">
      <el-table-column prop="payDate" label="日期" show-overflow-tooltip />
      <el-table-column prop="custom" label="客户" show-overflow-tooltip />
      <el-table-column prop="invoiceQuantity" label="开票数量" show-overflow-tooltip />
      <el-table-column prop="invoiceAmount" label="开票金额" show-overflow-tooltip />
      <el-table-column prop="payAmount" label="收款金额" width="200" show-overflow-tooltip>
        <template #default="scope">
          <el-input-number :controls="false" :precision="2" v-model="scope.row.payAmount" @change="save(scope.row)" />
        </template>
      </el-table-column>
      <el-table-column prop="residueAmount" label="当期结余" width="150" show-overflow-tooltip />
      <el-table-column prop="residueAmountAccumulate" label="累计余额" width="150" show-overflow-tooltip />
    </el-table>
  </div>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessage, ElMessageBox } from 'element-plus'
import api from '../../apis/api'
import cache from '../../utils/cache'
import util from '../../utils/util'

const router = useRouter()
const tabRef = ref()

const data = reactive({
  total: 0,
  list: [],
  payAmount: null,
  invoiceAmount: null,
  invoiceQuantity: null,
  residueAmountAccumulate: null,
})

const show = reactive({
  selected: [],
  customList: [],
})

api.get('/backend/dict/getByCode', { params: { code: 'custom' } }).then(res => {
  show.customList = res.dictList
})

var beforeMonth = new Date()
beforeMonth.setMonth(beforeMonth.getMonth() - 1)
const searchParam = reactive({})
const resetSearchParam = () => {
  searchParam.payDates = [beforeMonth, new Date()]
  searchParam.custom = null
}
resetSearchParam()

const commitFindList = () => {
  if (!searchParam.payDates || searchParam.payDates.length == 0) {
    ElMessage.error('请选择日期')
    return
  }
  const params = {}
  params.payDateGe = util.parseTime(searchParam.payDates[0], '{y}-{m}-{d}')
  params.payDateLe = util.parseTime(searchParam.payDates[1], '{y}-{m}-{d}')
  if (searchParam.custom) {
    params.customList = [searchParam.custom]
  } else {
    params.customList = show.customList.map(c => c.value)
  }
  api.post('/backend/salePay/invoiceAndPay', params).then(res => {
    data.list = res.list
    data.total = res.total
    data.payAmount = res.payAmount
    data.invoiceAmount = res.invoiceAmount
    data.invoiceQuantity = res.invoiceQuantity
    data.residueAmountAccumulate = res.residueAmountAccumulate
  })
}

const summary = () => {
  return ['合计', '', data.invoiceQuantity, data.invoiceAmount, data.payAmount, '', data.residueAmountAccumulate]
}

const selectionChange = () => {
  show.selected = tabRef.value.getSelectionRows()
}

const save = (row) => {
  console.log(row)
  if (!row.id) {
    api.post('/backend/salePay/add', {
      payDate: row.payDate,
      custom: row.custom,
      payAmount: row.payAmount,
    }).then(() => {
      commitFindList()
      ElMessage.success('更新成功')
    })
  } else {
    api.post('/backend/salePay/setById', {
      id: row.id,
      payAmount: row.payAmount,
    }).then(() => {
      commitFindList()
      ElMessage.success('更新成功')
    })
  }
}
</script>

<style lang="less"></style>